import store from "@/store/index";
import DatePicker from "@/components/DatePicker";
import { formatDate } from "@/utils/common";
import BreachManagementDialog from "./BreachManagementDialog";
import addBreachFields from "./addBreachFields";
import InfiniteLoading from "vue-infinite-loading";
import { breachesPost, breachesSlugPost } from "@/clients/tracelight";
import moment from "moment";

const allFilters = [
  "ALL",
  "NEW",
  "ON HOLD",
  "LOADING COMPLETE",
  "LOADING IN PROGRESS",
  "LOADING NEEDS REVIEW",
  "NORMALIZATION COMPLETE",
  "NORMALIZATION IN PROGRESS",
  "NORMALIZATION NEEDS REVIEW",
  "PARTITIONING COMPLETE",
  "PARTITIONING IN PROGRESS",
  "PARTITIONING NEEDS REVIEW",
];

export default {
  name: "BreachManagement",
  components: {
    BreachManagementDialog,
    DatePicker,
    InfiniteLoading,
  },
  data: () => ({
    toggleSort: 0,
    sortBy: "status",
    sortOrder: "asc",
    initialLoad: true,
    overlay: false,
    formatDate: formatDate,
    moment: moment,
    clearStart: false,
    clearEnd: false,
    fab: false,
    infiniteId: 1,
    dialog: false,
    searchInput: "",
    acceptSearchInput: true,
    debounceSearchInputTimeout: 200,
    snackbarContent: "",
    statusFilters: allFilters.concat(["INVALID"]),
    selectedStatus: allFilters,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Acquired", value: "acquisition_date" },
      { text: "Released", value: "release_date" },
      { text: "Status", value: "status" },
      { text: "Status Date", value: "status_created_at" },
    ],
    breachFields: addBreachFields,
    dialogFields: {},
    snackbar: false,
  }),
  mounted() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.updateUrlParams(urlParams);
  },
  computed: {
    breaches: function () {
      let breaches = store.state.breachManagement.breaches || [];
      for (const breach of breaches) {
        if (breach.acquisition_date) {
          breach.acquisition_date = formatDate(breach.acquisition_date);
        } else {
          breach.acquisition_date = "";
        }
        if (breach.release_date) {
          breach.release_date = formatDate(breach.release_date);
        } else {
          breach.acquisition_date = "";
        }
        if (breach.modified_at) {
          breach.modified_at = formatDate(breach.modified_at);
        } else {
          breach.modified_at = "";
        }
        if (breach.status_created_at) {
          breach.status_created_at = formatDate(breach.status_created_at);
        } else {
          breach.status_created_at = "";
        }
      }
      return breaches;
    },
  },
  methods: {
    updateUrlParams(urlParams) {
      for (const [key, value] of urlParams.entries()) {
        const val = urlParams.get(key);
        if (key == "status" && val !== "") {
          let initVal = val.replace("+", " ");
          initVal.replace(/^,/, "");
          let splitVal = initVal.split("%2C");
          this.selectedStatus = splitVal[0].split(",");
        }
        if (key == "acquired_before" && val !== "null") {
          this.$refs.datePickerEnd.dateData = moment(val).format("YYYY-MM-DD");
        }
        if (key == "acquired_after" && val !== "null") {
          this.$refs.datePickerStart.dateData =
            moment(val).format("YYYY-MM-DD");
        }
        if (key == "q" && val !== "null") {
          this.searchInput = val;
        }
        if (key == "sort_order") {
          store.commit("breachManagement/replaceValue", {
            key: "sortOrder",
            value: val,
          });

          if (val == "asc") {
            this.toggleSort = 0;
          }
          if (val == "desc") {
            this.toggleSort = 1;
          }
        }

        if (key == "sort_by") {
          this.sortBy = val;
          this.updateSortBy(val);
        }
      }
    },
    updateSortBy(value) {
      store.commit("breachManagement/replaceValue", {
        key: "sortBy",
        value: value,
      });
      if (this.initialLoad == false) {
        this.getBreaches();
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      store.dispatch("breachManagement/updatePage");
      store.dispatch("breachManagement/getBreaches", true).then((result) => {
        console.warn("LOADING", result);
        if (result === null) {
          $state.loaded();
          return;
        }
        if (result.length > 0) {
          $state.loaded();
          return;
        }
        if (result.length === 0) {
          $state.complete();
          return;
        }
      });
    },
    addBreach: function (payload) {
      if (!payload.hasOwnProperty("is_partial")) {
        payload.is_partial = false;
      }
      if (!payload.hasOwnProperty("is_identified")) {
        payload.is_identified = false;
      }
      if (!payload.hasOwnProperty("is_confirmed")) {
        payload.is_confirmed = false;
      }
      this.dialog = false;
      this.overlay = true;
      breachesPost(payload, {})
        .then((response) => {
          console.warn(response);
          this.overlay = false;

          this.snackbarContent = `Breach created — ${payload.name}`;
          this.snackbar = true;

          store.dispatch("breachManagement/getBreaches");
        })
        .catch((error) => {
          console.warn(error.response);
          this.snackbarContent = `Breach error — ${error.response.data.message}`;
          this.snackbar = true;
          this.overlay = false;
          return;
        });
    },
    navToPermalink: function (row) {
      this.$router.push({
        name: "BreachPermalink",
        params: { breachId: row.external_id },
      });
    },
    dateFormat: function (dateStr) {
      let newDate = formatDate(dateStr);
      if (newDate == "Invalid date") {
        return "---";
      } else {
        return newDate;
      }
    },
    getBreaches: function () {
      this.infiniteId += 1;
      store.dispatch("breachManagement/getBreaches");
    },
    updateDateRangeStart: function (dates) {
      if (dates !== [null]) {
        this.clearStart = false;
      }
      store.commit("breachManagement/replaceValue", {
        key: "acquireDateStart",
        value: dates[0],
      });
      if (this.initialLoad == false) {
        this.getBreaches();
      }
    },
    updateDateRangeEnd: function (dates) {
      if (dates !== [null]) {
        this.clearEnd = false;
      }
      store.commit("breachManagement/replaceValue", {
        key: "acquireDateEnd",
        value: dates[0],
      });

      this.getBreaches();
      this.initialLoad = false;
    },
  },
  watch: {
    toggleSort: {
      handler: function (val) {
        if (val == 0) {
          this.sortOrder = "asc";
        }
        if (val == 1) {
          this.sortOrder = "desc";
        }

        store.commit("breachManagement/replaceValue", {
          key: "sortOrder",
          value: this.sortOrder,
        });
        if (this.initialLoad == false) {
          this.getBreaches();
        }
      },
      deep: true,
      immediate: true,
    },
    dialogFields: function (val) {
      console.log(val);
    },
    searchInput: function (val) {
      store.commit("breachManagement/replaceValue", {
        key: "searchTerm",
        value: val,
      });

      if (this.acceptSearchInput) {
        this.acceptSearchInput = false;
        window.setTimeout(() => {
          if (this.initialLoad == false) {
            this.getBreaches();
          }
          this.acceptSearchInput = true;
        }, this.debounceSearchInputTimeout);
      }
    },
    selectedStatus: function (val) {
      let newStatus = val;
      if (val.includes("ALL") || val.length == 0) {
        newStatus = [];
      }
      store.commit("breachManagement/replaceValue", {
        key: "status",
        value: newStatus,
      });
      if (this.initialLoad == false) {
        this.getBreaches();
      }
    },
  },
};
