import { formatDate } from "@/utils/common";
export default [
  {
    text: "Name",
    key: "name",
    widget: "text",
    value: "",
  },
  {
    text: "Source",
    key: "source",
    widget: "text",
    value: "",
  },
  {
    text: "Acquired",
    key: "acquisition_date",
    widget: "date",
    value: formatDate(new Date()),
  },
  {
    text: "Released",
    key: "release_date",
    widget: "date",
    value: "",
  },
  {
    text: "Archive Password",
    key: "archive_password",
    widget: "text",
    value: "",
  },
  {
    text: "Is Identified",
    key: "is_identified",
    widget: "checkbox",
    value: false,
  },
  {
    text: "Is Partial",
    key: "is_partial",
    widget: "checkbox",
    value: false,
  },
  {
    text: "Is Confirmed",
    key: "is_confirmed",
    widget: "checkbox",
    value: false,
  },
  {
    text: "Description",
    key: "description",
    widget: "textarea",
    value: "",
  },
  {
    text: "Notes",
    key: "notes",
    widget: "textarea",
    value: "",
  },
];
